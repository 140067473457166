@import '../../../../../Shared/CSS/variables';
.arcutis-engagement-root{
    width:100%;   
    .list-container {
        padding: 0 1%;

        .targetCard {
            margin-top: 10px;
            max-height: calc(100vh - 200px);
            overflow: auto;
            .edit-meet-button{
                background: $btn-primary-bg !important;
            }
        }
        .targetCard .ant-card-body {
            padding: 0;
        }
        .targetCard .ant-card-head{
            font-size: 18px;
            margin: 0;
            padding: 0;
        }
        .profile-form-root {
            border-bottom : 1px solid #f0f1f1;
            font-weight   : 700;
            margin-bottom : 7px;
            padding-bottom: 2px;
            font-size     : 16px;
            // padding-left  : 13px;
            width         : 100%;
        }

        .form-group {
            margin-bottom: 4rem;
        }

        .form-label {
            color        : #6a6d70;
            font-size    : 14px;
            font-weight  : 600;
            margin-bottom: 0;
            margin-left  : 1px;
        }

        .text-only {
            color      : #32363a;
            font-weight: 500;
            padding    : 3px 0;
            white-space: pre-line;
            word-break : break-word;
        }

        .ant-table-wrapper table {
            border: 1px solid #f0f0f0 !important;
        }

        .ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-thead >tr>td {
            position: relative;
            color: rgba(0, 0, 0, 0.88);
            font-weight: 600;
            text-align: left;
            // background: #f6f6f6 !important;
            background-color: white;;
            border-bottom: none !important;
            transition: background 0.2s ease;
            border-bottom: 1px solid #f0f0f0 !important;
        }
        .ant-table-wrapper .ant-table-tbody >tr >td {
            border-bottom: 1px solid #f0f0f0 !important;
        }
        .temp{
            .ant-card .ant-card-body {
                padding: 0px;
                border-radius: 0 0 8px 8px;
            }
        }
        .headerLabel{
            font-weight: 700;
            font-size: 20px;
            margin:20px 0
        }
        .sub-header-label{
            font-weight: 500;
            font-size: 22px;
        }
        .arcutisName{
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            margin: 10px 0;
        }
        .arcutisRole{
            text-align: center;
            font-size: 15px;
            font-weight: 700;
            margin: 10px 0;
        }
        .arcutisButtons{
            border-radius: 50%;
            padding: 0 10px;
            margin:0 8px;
        }
        .sectionHeaderName{
            font-size: 22px;
            font-weight: 500;
            margin: 20px 0;
        }
        .sectionLabelName{
            font-size: 18px;
            font-weight: 500;
            margin:15px 0;
        }
        .sectionValueName{
            font-size: 18px;
        }
        .labelSectionValueName{
            font-size: 18px;
            color:#6A6D70;

        }
        .headerLink{
            color:#4E7CB1;
            font-size: 18px;
        }
        .headerLink:hover{
            text-decoration: underline;
        }
    }

}
.table-kol {
    margin-top: 20px;
    text-align: center;
  }

  .edit-icon{
    margin-left: 5px;
    font-size: larger;
    color: #FFE539;
  }
      
.filter-header{
    padding: 0px !important;
    height: 40px !important;
    background: white !important;
    padding:15px 10px !important
}
.filter-content{
    height: calc(100vh - 85px) !important;
    overflow-x: auto;
}
.custom-menu .ant-menu-item-selected {
    background-color: black !important;
    color: white !important;
  }
.custom-menu .ant-menu-item {
font-size: 16px !important; 
font-weight: 500 !important;
}

.custom-menu .ant-menu-item .ant-menu-title-content {
font-size: 16px !important; 
font-weight: 500 !important; 
color:"#5E6164"
}

.custom-menu .ant-menu-item .anticon {
font-size: 20px !important; 
font-weight: 500 !important; 
}

.sectionValueName .ant-checkbox-inner {
    border-color: black; 
  }
  
.sectionValueName .ant-checkbox-checked .ant-checkbox-inner {
    background-color: black; 
    border-color: black; 
}

.sectionValueName .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: white; 
}
  