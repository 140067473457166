@import './../../Shared/CSS/variables';
.main-Content {
  // background-color: #fff;
  height  : calc(100% - 46px);
  overflow: hidden;

  .ant-typography strong{
    overflow          : hidden;
    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .reports-table{
    overflow: auto;
    max-height: calc(100vh - 160px);
    .ant-table-wrapper .ant-table-thead>tr>th, .survey-main :where(.css-dev-only-do-not-override-usln0u).ant-table-wrapper .ant-table-thead>tr>td {
      background: #ebebeb !important;
      border-bottom: 1px solid #f0f0f0;
      color: rgba(0, 0, 0, .88);
      font-weight: 600;
      position: relative;
      text-align: start;
      transition: background .2s ease;
    }
    .ant-table-wrapper .ant-table-tbody >tr >td {
      transition: background 0.2s, border-color 0.2s;
      border-bottom: 1px solid #f0f0f0 !important;
    }
    .ant-table-body>table>tbody>tr>td, .survey-container-small .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th {
      -webkit-border-end: none !important;
      border-inline-end: none !important;
    }
    .ant-table-wrapper .ant-table-tbody > tr > td {
      transition: background 0.2s, border-color 0.2s;
      // border: none !important;

  } 
  }

  .report-mobile-card{
    .ant-card .ant-card-head{
      // background: #ebebeb!important;
    }
  }

  .home-tabs {
    // padding-top: 1% !important;
    padding    : 0px 1%;
    display    : flex;
    text-align : center !important;
  }
  .home-side-header{
    // font-family: 'Roboto';
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: left;
  }
  .home-side-sub-header{
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: left;
  }
  .ant-card .ant-card-head {
    padding: 0 15px !important;
    height: 40px !important;
    min-height: 40px !important;
    padding: 0 !important;
    // height: 40px !important;
    // min-height: 40px !important;
  }

  .ant-card:not(.ant-card-bordered) {
     box-shadow: none !important; 
  }

  .ant-card .ant-card-head-wrapper {
    max-height: 30px !important;
  }
  .ant-card .ant-card-head-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: left;
    padding:10px;
  }
  .noActiveSurveys{
    font-size: 20px;
    margin: 0 5px;
    font-weight: 500;
  }
  .home-action-button{
    font-size: 13px;
    font-weight: 500;
    // line-height: 24px;
    letter-spacing: 0.25px;
    text-align: left;
  }
  .sectionLabelName{
    font-size: 18px;
    font-weight: 500;
    margin:15px 0;
}
.sectionValueName{
    font-size: 18px;
    font-weight: 500;
}
.labelSectionValueName{
    font-size: 18px;
    color:#6A6D70;
    margin-bottom: 5px;

}
.headerLabel{
  font-weight: 700;
  font-size: 20px;
  margin:20px 0
}
.sub-header-label{
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 0;
}
.sub-header{
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0;
  margin: 0;
}

  .tabs-list {
    background-color: #fff;
    border          : 1px solid #E1E1E1;
    width           : fit-content;

    padding            : 6px 2%;
    // margin          : 0px 10px;
    color              : #ffffff;
    border-radius      : 2px;
    cursor             : pointer;

    &:hover {
      background-color: #e6e6e6;

      span {
        color      : #000000;
        // border-left: 1px solid #000000;
      }
    }

    &.active {
      background-color: #000000;
      color           : #fff;
      font-weight     : 400;

      span {
        color: #fff;
      }
    }

    span {
      color: #000000;
    }
  }
  // .ant-card-body{
  //   width: 100%;

  // }
  // .ant-carousel .slick-slide {
  //   display: flex;
  //   justify-content: center;
  // }
  // .ant-carousel .slick-slide div {
    // width: 90%;

  // }
  
  // .ant-card-bordered {
  //   box-shadow: none;
  // }
  
  // .ant-card {
  //   border-radius: 8px;
  //   background: #f5f5f5;
  //   width:'100%';
  // }
  [class~='ant-carousel'] {
    [class~='slick-slider'] {
      li{
        width: 10px;
      }
      li button {
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: rgb(180, 179, 179);
            // margin-left: 10px;
            // margin-right: 10px;
          }
       li.slick-active button {
            // width: 15px;
            // height: 7px;
            // border-radius: 15% !important;
            background-color: grey;
            width: inherit;
            height: 7px;
            // top: 276px;
            // left: 568px;
            // gap: 0px;
            border-radius:  5px 5px 5px 5px;
            // opacity: 0px;
            // background-color: #5D6063;
          }
    }   
  }
  .internalCard{
  .ant-card-body{
    justify-content: center;
    display: flex;
    width: 100%;
    .ant-row.ant-row-no-wrap.ant-row-middle{
      background: #f5f5f5;
      padding: 10px 15px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      width: 85% !important;
      padding:15px;
      margin-bottom:30px;
    }
  }
  .ant-row.ant-row-no-wrap.ant-row-middle{
    width: 90%;
  }
  }
  .surveyCard{
    .ant-card-body{
      padding: 0;
    }
  }

  // .ant-btn-link {
  //   margin-left: auto;
  // }

  .meetingList {
    display        : flex;
    padding        : 0px 20px;
    justify-content: space-between;
    margin-top     : 20px;
  }

  .conference {
    padding   : 0px 20px;
    margin-top: 10px;
    // height    : calc(100vh - 185px);
    // overflow  : hidden;
    // .reports-table .ant-table-wrapper{
    //   border: 2px solid #f0f0f0 !important;
    // }

    .reports-table .ant-table-wrapper .ant-table-thead > tr > th, .reports-table :where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-thead > tr > td {
      position: relative;
      color: rgba(0, 0, 0, 0.88);
      font-weight: 600;
      text-align: left;
      font-size: 13px;
      background-color: white;
      // border-bottom: none !important;
      transition: background 0.2s ease;
      // border: 1px solid #f0f0f0 !important;
    }

    .reports-table .ant-table-wrapper .ant-table-thead{
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: #EBEBEB; 
      padding:15px 7px 15px 15px !important; 
      margin: 4px; 
    }
    .reports-table .ant-table-cell{
      text-align: left !important;
      padding: 10px 10px 10px 15px !important;
      font-size: 13px;
    }
    .ant-table-wrapper .ant-table-tbody >tr >td {
      transition: background 0.2s, border-color 0.2s;
      // border: 1px solid #f0f0f0;
    }

    .ant-card-body {
      padding: 0px;
    }
    .ant-card-head-wrapper{
      // align-items: baseline;
    }
    .conference-height {
      // height        : calc(100vh - 180px);
      // overflow      : auto;
      // padding-top: 31px;
    }

    .justify-content {
      justify-content: space-between;
    }

    .conference-card {
      margin-bottom: 4%;
      // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      border-radius: 8px 8px 0 0;

      .ant-card-body {
        padding: 13px;
      }
      .ant-card-bordered {
        border: none !important;
      }

      .parent {
        position: relative;
        top     : 0;
        left    : 0;
      }

      .image1 {
        position: relative;
        top     : 0;
        left    : 0;
        border  : 1px red solid;
      }

      .image2 {
        position: absolute;
        top     : 30px;
        left    : 30px;
        border  : 1px green solid;
      }

      h3 {
        margin: 0px;
        color : #fff;
      }

      h5 {
        margin-top: 0.2em;
        color     : #fff;
      }

      .conference-text {
        // display        : flex;
        // justify-content: space-between;
      }

      .conference-team {
        color: #035876;
      }

      .hrs {
        border       : 1px solid #ddd;
        padding      : 5px 10px;
        border-radius: 50%;
        text-align   : center;
        color        : #035876;
        margin       : 0px 1%;
      }

      .conference-text-color {
        color: #fff;
      }

      .conference-time {
        margin-top: 5px;
      }
    }

    .attendee-body {
      margin-bottom: 4%;
      .avatar-Text{
        font-size: 20px;
      }

      .ant-card-body {
        padding: 14px;
      }

      h4 {
        margin: 0px;
        color : #3E8DAF;
      }

      h5 {
        margin: 0px;
      }
    }

    .con-row {
      width: 100%;

      .tile-profile {
        background-color: #8DB0AB;
        color           : #fff;
        margin          : 2%;
        padding         : 25px 0px;
        cursor          : pointer;
        border-radius   : 2px;
        box-shadow      : 1px 1px 11px 6px #cccccc;

        &:hover {
          border-radius: 2px;
          box-shadow   : 1px 1px 10px 6px #9f9f9f;
        }
      }

      h3 {
        color        : #fff;
        margin-bottom: 5px !important;
      }

      h5 {
        color : #fff;
        margin: 5px 0px;
      }

      .con-div {
        justify-content: center;
        display        : grid;

        h5 {
          color : #fff;
          margin: 0px 0px !important;
        }
      }
    }
  }
}

.width-100 {
  width: 100%;
}

.class-25{
  width:25%;
}

.class{
  width: 50%;
}

.list-icon {
  display        : flex;
  // padding: 10px 2%;
  justify-content: left;
  width          : 100px;

  .anticon {
    border : 1px solid #dee2e6;
    padding: 8% 15%;
    margin : 0px !important;

    svg {
      font-size: x-large;
    }
  }
}

.search-kol {
  width: 50%;
}

.search-kol-mobile{
  width: 100%;
}

.display-flex {
  display: flex;
}

.justify-content {
  justify-content: space-between;
}

.pointer {
  cursor: pointer;
}

.text-ellipsis-one-line {
  overflow          : hidden;
  display           : -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color             : #B9B9B9;
}

.text-ellipsis-one-line-black {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #000000;
}

.total-count {
  width      : 50%;
  text-align : right;
  padding: 0px !important;
  font-weight: 400 !important;
  margin-top     : 0px !important;
  margin-bottom     : 0px !important;
  font-size: 16px !important;
  opacity: 0.5;
  align-self: center;

}

//likhith
// .containerr{
//   width: 200px;
//   height: 300px;
//   overflow-y: scroll;
//   background-color: lime;
// }
// .containerr > * {
//   margin: 1opx;
//   height: 50px;
//   width: calc(100% - 20px);
//   border:solid;
// }

// .ant-table-wrapper .ant-table-tbody >tr >td {
//   transition: background 0.2s, border-color 0.2s;
//   border: 1px solid #f0f0f0;
// }
.edit-meet-button{
  background: $btn-primary-bg !important;
}

// .ant-drawer {
//   position: fixed;
//   inset: 0;
//   z-index: 9999 !important;
//   pointer-events: none;
// }

.disabled-cursor{
  cursor: not-allowed;
  pointer-events: all !important;
}

.ant-table-cell{
  text-align: left;
}

.ant-table-wrapper .ant-table-thead > tr > td {
  text-align: left;
}

.submit-block{
  display: flex;
  justify-content: end;
  padding-top: 20px;
}
@media  screen and (max-width:767px) {
  .conference {
    padding   : 0px 5px !important;
  }
}