.dropdown-follow-up {
    .ant-popconfirm-buttons {
      .ant-btn-primary {
        color: #fff;
        background: rgb(0, 0, 0) !important;
        box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
      }
    }
    .ant-select-dropdown {
      width: 20% !important;
    }
  }
  
  .follow-container .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0 !important;
    padding: 8px 16px;
  }
  
  .follow-container .ant-table-wrapper .ant-table-thead > tr > td {
    border-bottom: 1px solid #f0f0f0 !important;
    padding: 8px 16px;
  }
  
  .follow-container .ant-table-wrapper table {
    height: 50px;
    // table-layout: fixed !important;
  }
  
  .follow-container .ant-table-wrapper .ant-table-body table {
    height: 50px;
    // table-layout: inherit !important;
  }
  
  .follow-container-small .ant-table-wrapper table {
    height: 50px;
    table-layout: fixed !important;
  }
  
  .follow-container-small .ant-table-wrapper .ant-table-body table {
    height: 50px;
    table-layout: inherit !important;
  }
  
  .follow-container-small .width-action-small{
    min-width: 70px !important;
  }
  
  .ant-btn-primary {
    color: #fff;
    background: rgb(0, 0, 0) !important;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  }
  
  .ant-btn-primary:disabled, :where(.css-dev-only-do-not-override-usln0u).ant-btn-primary.ant-btn-disabled {
    cursor: not-allowed;
    border-color: #d9d9d9;
    color: lightgrey;
    background: black;
    box-shadow: none;
    opacity: 0.5;
  }
  
  .action-on { 
    .ant-select-dropdown {
      width: 20% !important;
    }
  }