@import '../../../../../Shared/CSS/variables';
.Tll-scorecard-root{
    width:100%;   
    .list-container {
        padding: 0 1%;

        .Tll-scorecard-Card {
            margin-top: 10px;
            max-height: calc(100vh - 200px);
            overflow: auto;
            .edit-meet-button{
                background: $btn-primary-bg !important;
            }
        }
        .Tll-scorecard-Card .ant-card-body {
            padding: 10px;
        }

        .headerLabel{
            font-weight: 700;
            font-size: 20px;
            margin:20px 0
        }
        .sub-header-label{
            font-weight: 500;
            font-size: 22px;
            margin-bottom: 0;
        }
        .userDetails{
            justify-content: center;
            display: flex;
            flex-direction: column;
        }
    }

    .adv-container {
        .ant-table css-dev-only-do-not-override-1xg9z9n {
            height: calc(100vh - 382px);
            overflow: auto;
        }
    }

    .ant-table-content {
        height: calc(100vh - 550px);
        overflow: auto;
    }

    .edit-container .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td{
        border-inline-end: none !important;
    }
    .edit-container .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th{
        border-inline-end: none !important;
    }
}

.edit-container .ant-table-wrapper table {
    height: 50px;
    // table-layout: fixed !important;
  }
  
  .edit-container .ant-table-wrapper .ant-table-body table {
    height: 50px;
    // table-layout: inherit !important;
  }

.edit-container-small .ant-table-wrapper table {
    height: 50px;
    table-layout: fixed !important;
  }

  .edit-container-small .ant-table-wrapper .ant-table-body table {
    height: 50px;
    table-layout: inherit !important;
  }

  .edit-container-small .width-action-small {
    min-width: 70px !important;
  }

.edit-container-small .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td{
    border-inline-end: none !important;
}
.edit-container-small .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th {
    border-inline-end: none !important;
}

.podium-noble-statements {
    .ant-table-cell{
    word-break: break-all;
    white-space: normal;
    max-width: 200px;
    }
    td.ant-table-cell ul {
        padding-left: 10px; 
        margin-left: 0; 
        // list-style-position: inside; /* Moves bullet points inside the content */
      }
      
      td.ant-table-cell ul li {
        padding-left: 0; /* Removes any internal padding */
      }
}