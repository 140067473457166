@import '../../../Shared/CSS/variables';
.list-container {
    padding: 0 1%;

    .profile {
        margin-top: 10px;
        height: calc(100vh - 110px);
        overflow: auto;
        .edit-meet-button{
            background: $btn-primary-bg !important;
        }
    .ant-card-body {
        padding: 24px !important;
    }

    }

    .profile-form-root {
        border-bottom : 1px solid #f0f1f1;
        font-weight   : 700;
        margin-bottom : 7px;
        padding-bottom: 2px;
        font-size     : 16px;
        // padding-left  : 13px;
        width         : 100%;
    }

    .form-group {
        margin-bottom: 10px;
    }

    .form-label {
        color        : #6a6d70;
        font-size    : 14px;
        font-weight  : 600;
        margin-bottom: 0;
        margin-left  : 1px;
    }

    .text-only {
        color      : #32363a;
        font-weight: 500;
        padding    : 3px 0;
        white-space: pre-line;
        word-break : break-word;
    }

    .ant-table-wrapper table {
        border: 1px solid #f0f0f0 !important;
    }

    .ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-thead >tr>td {
        position: relative;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        text-align: left;
        // background: #f6f6f6 !important;
        background-color: white;;
        border-bottom: none !important;
        transition: background 0.2s ease;
        border-bottom: 1px solid #f0f0f0 !important;
    }
    .ant-table-wrapper .ant-table-tbody >tr >td {
        border-bottom: 1px solid #f0f0f0 !important;
    }
    .temp{
        .ant-card .ant-card-body {
            border-radius: 0 0 8px 8px;
        }
    }
}

.table-kol {
    margin-top: 20px;
    text-align: center;
  }

  .edit-icon{
    margin-left: 5px;
    font-size: larger;
    color: #FFE539;
  }