.form-dyn-builder{
    .ant-col{
        padding: 4px 4px 0 0 !important;
    } 
    .ant-cascader-menu {
        min-width: 22rem;
    }
    .optional{
        color: #bbb5b5;
    }
    .submit-btn {
        position: absolute !important;
    }
    .ant-select-multiple .ant-select-selection-item-content{
        max-width: 9rem !important;
    }
    .ant-form-item-control-input {
        min-height: 0;
    }
    .form-question{
        display: flex;
        // align-items: center;
        justify-content: space-between;
    }
    .field-label{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        font-family: 'Inter-Medium';
        .ant-btn {
            padding:0 12px;
            height:2px;
        }
        .ant-btn>.anticon+span{
            margin-left: 4px;
        }
    }
    .info-button {
        padding-top: 0px;
        height: auto;
    }
    .required-star{
        color: red;
    }

    .horizontal-radio-group .ant-space {
        display: flex;
        flex-direction: row;
      }

    .note-header {
        font-weight: bolder;
    }

    .field-header {
        margin: 0 !important;
    }

    .header-container {
        padding: 10px;
        border: 1px solid;
        width: 100%;
        background: #f0f0f0;
    }
}
