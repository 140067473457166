.activityDetailsContainer{
    background-color: white;
    padding: 20px;
    width:100%;
    .activityDetailsCard{
        background-color: #F7F7F7;
        padding: 20px;
        overflow: auto;
        height:100%;
    }
    .link{
        color:#4E7CB1 !important;
        cursor:pointer;
    }
    .link:hover{
        text-decoration: underline;
    }
}