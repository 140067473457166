.MeetingFollowUp {
  height: calc(100% - 46px);
  overflow: hidden;

  .ant-select {
    min-height: 0;
    background: #f0f0f0 !important;
  }
  
  .ant-select-selector {
    border: 1px solid #d9d9d9;
    background: none !important;
  }

  .follow-container .ant-table-wrapper .ant-table-tbody>tr>td {
    border-bottom: 1px solid #f0f0f0 !important;
    padding: 8px 16px;
  }

  .follow-container .ant-table-wrapper .ant-table-thead>tr>td {
    border-bottom: 1px solid #f0f0f0 !important;
    padding: 8px 16px;
  }

  .follow-container .ant-table-wrapper table {
    height: 50px;
    // table-layout: fixed !important;
  }

  .follow-container .ant-table-wrapper .ant-table-body table {
    height: 50px;
    // table-layout: inherit !important;
  }

  .follow-container-small .ant-table-wrapper table {
    height: 50px;
    table-layout: fixed !important;
  }

  .follow-container-small .ant-table-wrapper .ant-table-body table {
    height: 50px;
    table-layout: inherit !important;
  }

  .follow-container-small .width-action-small {
    min-width: 70px !important;
  }

  .ant-btn-primary {
    color: #fff;
    background: rgb(0, 0, 0) !important;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  }

  .ant-btn-primary:disabled,
  :where(.css-dev-only-do-not-override-usln0u).ant-btn-primary.ant-btn-disabled {
    cursor: not-allowed;
    border-color: #d9d9d9;
    color: lightgrey;
    background: black;
    box-shadow: none;
    opacity: 0.5;
  }

  .ant-layout .ant-layout-sider {
    background: white !important;
}
.ant-layout-sider-children{
    height: calc(100vh - 145px) !important;
    // overflow-x: hidden;
}
.ant-divider-horizontal {
    margin: 0px 0 !important;
}
.ant-collapse{
    border: none !important;
    background-color: none !important;
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header {
    // border: none !important;
    background-color: white !important;
    padding: 6px 16px !important;
}
.ant-collapse .ant-collapse-content {
    border-top: none !important;
}
.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 0px 16px !important;
}
.builder{
    .ant-space {
        display: grid !important;
    }
}
.ant-input {
    min-height: 0;
    background: white !important;
}
.ant-select{
    min-height: 0;
    background: #f0f0f0 !important;
}
.ant-select-selector {
    border: 1px solid #d9d9d9;
    background: none !important;
}
.ant-picker-outlined {
    min-height: 0;
    background: white !important;
}
.ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-usln0u).ant-table-wrapper .ant-table-thead >tr>td {
    position: relative;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    text-align: start;
    background: #EBEBEB !important;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.2s ease;
}
.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td {
    border-inline-end: none !important;
}

.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >thead>tr>th{
    border-inline-end: none !important;
}

.survey-container .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td{
    border-inline-end: none !important;
}
.survey-container .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th{
    border-inline-end: none !important;
}

.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th{
    border-inline-end: none !important;
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header {
    // border: none !important;
    background-color: white !important;
    padding: 10px 20px !important;
}

.MeetingFollowUp .ant-layout-footer {
  background: none !important;
  padding: 0px;
}

.survey-filter-header {
  padding: 0px !important;
  height: 45px !important;
}

.survey-filter-content {
  height: calc(100vh - 145px) !important;
  overflow-x: auto;
}

}



.survey-container .ant-table-wrapper table {
  height: 50px;
  // table-layout: fixed !important;
}

.survey-container .ant-table-wrapper .ant-table-body table {
  height: 50px;
  // table-layout: inherit !important;
}

.survey-container-small .ant-table-wrapper table {
  height: 50px;
  table-layout: fixed !important;
}

.survey-container-small .ant-table-wrapper .ant-table-body table {
  height: 50px;
  table-layout: inherit !important;
}

.survey-container-small .width-action-small {
  min-width: 70px !important;
}

.survey-container-small .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td{
  border-inline-end: none !important;
}
.survey-container-small .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th {
  border-inline-end: none !important;
}
.ant-popover {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%) !important;
}
