.activityTimeLineContainer{
    background-color: white;
    padding: 20px;
    width:100%;
    .activityTimeLineCard{
        background-color: #F7F7F7;
        padding: 20px 0;
        overflow: auto;
        height:100%;
        padding-left:50px;
    }
    /* custom-timeline.css */

/* Adjust the overall timeline item */
.custom-timeline .ant-timeline-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  /* Adjust the timeline label (move more to the left) */
  .custom-timeline .ant-timeline-item-label {
    margin-right: 40px; /* Increase this to move the label further to the left */
    flex-shrink: 0; /* Prevent shrinking of the label */
  }
  
  /* Center the content between the pointer and the label */
  .custom-timeline .ant-timeline-item-content {
    text-align: center; /* Center the content horizontally */
    flex-grow: 1; /* Allow content to grow between label and pointer */
  }
  
  /* Adjust the track (line) to be centered */
  .custom-timeline .ant-timeline-item-tail {
    top: 50%; /* Center the line vertically */
    left: -20px; /* Adjust this to move the track closer to the pointer */
    width: 2px; /* Adjust the width of the track */
    height: calc(100% - 20px); /* Ensure the track connects the items properly */
  }
  
.ant-timeline.ant-timeline-label .ant-timeline-item-tail{
    inset-inline-start: 18%;
    border-inline-start: 8px solid #A2B9D3;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
    width: 15%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height:100%;
    text-align: center;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-head{
    inset-inline-start: 18%;
    width: 15px;
    height: 15px;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content{
    inset-inline-start: 20%;
    width: 100%;
}
}